// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
// import FooterNewsletter from "./footer_1_newsletter"

const Footer = () => (
    <footer className="footer">
        {/* <FooterNewsletter/> */}

        <div className="footer__content">

            <div className="footer__section">
                <nav className="footer__navigation footer__navigation--main" role="navigation">
                    <ul>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15201">
                            <a href="/#">Visita</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type_archive menu-item-object-mostre menu-item-27">
                            <a href="/#">Mostre</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type_archive menu-item-object-collezione menu-item-28">
                            <a href="/#">Collezione</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type_archive menu-item-object-calendario menu-item-29">
                            <a href="/#">Cosa accade</a>
                        </li>
                    </ul>
                </nav>
                <nav className="footer__navigation footer__navigation--secondary" role="navigation">
                    <ul>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15226">
                            <a href="/#">Chi siamo</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15222">
                            <a href="/#">Educazione e Ricerca</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type_archive menu-item-object-pubblicazioni menu-item-15223">
                            <a href="/#">Pubblicazioni</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-16693">
                            <a href="/#">Sostieni</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type_archive menu-item-object-sala-media menu-item-15224">
                            <a href="/#">Area Media</a>
                        </li>
                        <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15225">
                            <a href="/#">Amministrazione Trasparente</a>
                        </li>
                    </ul>
                </nav>
                <div className="header__lang">
                    <a className="header__lang-button header__lang-button--active" href="/">It</a><a
                    className="header__lang-button " href="/">En</a>
                </div>
            </div>

            <div className="footer__section footer__contacts">
                <div className="footer__google">
                    Il museo madre su
                    <br/><a href="/#" target="_blank">Google Arts & Culture</a>
                </div>
                <div className="footer__branding">museo madre</div>
                <div className="footer__info">
                    <div className="footer__contact">
                        <a href="/#" target="blank">Via Settembrini 79, 80139 Napoli, Italia</a>
                        <br/><a href="/#" className="spaced">info@</a> <a href="tel:+39089123456"
                                                                          className="spaced">+39.089123456</a>
                    </div>
                    <nav className="footer__social">
                        <ul>
                            <li className="facebook menu-item menu-item-type-custom menu-item-object-custom menu-item-30">
                                <a target="_blank" href="/#">Facebook</a>
                            </li>
                            <li className="twitter menu-item menu-item-type-custom menu-item-object-custom menu-item-31">
                                <a target="_blank" href="/#">Twitter</a>
                            </li>
                            <li className="instagram menu-item menu-item-type-custom menu-item-object-custom menu-item-32">
                                <a target="_blank" href="/#">Instagram</a>
                            </li>
                            <li className="spotify menu-item menu-item-type-custom menu-item-object-custom menu-item-15719">
                                <a href="/#">Spotify</a>
                            </li>
                            <li className="telegram menu-item menu-item-type-custom menu-item-object-custom menu-item-16649">
                                <a href="/#">Telegram</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="footer__section footer__colophon">
                <div className="footer__logos">
                    <div className="colophon__logo colophon__logo--copyright"></div>
                    <div className="colophon__logo colophon__logo--scabec"></div>
                    <div className="colophon__logo colophon__logo--poc"></div>
                </div>
                <div className="footer__info">
                    <div className="footer__copyright">
                        © {new Date().getFullYear()}
                    </div>
                    <nav className="footer__legal">
                        <ul>
                            <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15287">
                                <a href="/#">Termini e condizioni</a>
                            </li>
                            <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15288">
                                <a href="/privacy">Informativa privacy</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    </footer>
);

export default Footer