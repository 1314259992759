// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, {Component} from 'react';


class Header extends Component {
    state = {
        isActive: false,
        isScrollingTop: true,
    };

    toggleMenu = (e) => {
        e.preventDefault();
        //do something...
        this.setState({isActive: !this.state.isActive});
    };


    handleScroll = () => {
        this.setState({ isActive: false, isScrollingTop: window.pageYOffset === 0});
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    render() {
        return (
            <header className="header" role="banner">
                <div className={this.state.isScrollingTop === false ? "header__branding header__branding--hidden": "header__branding"}>
                    <a href="/" className="header__logo header__logo--left" rel="home">museo</a>
                    <a href="/" className="header__logo header__logo--right" rel="home">madre</a>
                </div>
                <div className="header__trigger">
                    <a href="/" onClick={e => this.toggleMenu(e)}
                       className={this.state.isActive === true ? "menu__trigger menu__trigger--active" : "menu__trigger"}>╳</a>
                </div>
                <div className={this.state.isActive === true ? "header__menu header__menu--active" : "header__menu"}>
                    <nav className="header__navigation" role="navigation">
                        <ul>
                            <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15201">
                                <a href="/#">Visita</a>
                            </li>
                            <li className=" menu-item menu-item-type-post_type_archive menu-item-object-mostre menu-item-27">
                                <a href="/#">Mostre</a>
                            </li>
                            <li className=" menu-item menu-item-type-post_type_archive menu-item-object-collezione menu-item-28">
                                <a href="/#">Collezione</a>
                            </li>
                            <li className=" menu-item menu-item-type-post_type_archive menu-item-object-calendario menu-item-29">
                                <a href="/#">Cosa accade</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="header__footer">
                        <nav className="header__secondary">
                            <ul>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15226">
                                    <a href="/#">Chi siamo</a>
                                </li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15222">
                                    <a href="/#">Educazione e Ricerca</a>
                                </li>
                                <li className=" menu-item menu-item-type-post_type_archive menu-item-object-pubblicazioni menu-item-15223">
                                    <a href="/#">Pubblicazioni</a>
                                </li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-16693">
                                    <a href="/#">Sostieni</a>
                                </li>
                                <li className=" menu-item menu-item-type-post_type_archive menu-item-object-sala-media menu-item-15224">
                                    <a href="/#">Area Media</a>
                                </li>
                                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-15225">
                                    <a href="/#">Amministrazione Trasparente</a>
                                </li>
                            </ul>
                        </nav>
                        <div className="header__tools">
                            <div className="header__search">
                            {/*

                                <form role="search" method="get" id="searchform" action="#">
                                    <div className="search__form">
                                        <div className="search__button">
                                            <button className="input--reset" name="button" type="submit">
                                                <i className="material-icons search__icon">search</i>
                                            </button>
                                        </div>
                                        <div className="search__input">
                                            <input className="input--reset" name="s" type="text" placeholder="Ricerca"
                                                   defaultValue/>
                                        </div>
                                    </div>
                                </form>

                            */}
                            </div>
                            <div className="header__lang">
                                <a className="header__lang-button header__lang-button--active" href="/">It</a><a
                                className="header__lang-button " href="/">En</a>
                            </div>
                            <nav className="header__social">
                                <ul>
                                    <li className="facebook menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" href="/#">Facebook</a>
                                    </li>
                                    <li className="twitter menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" href="/#">Twitter</a>
                                    </li>
                                    <li className="instagram menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" href="/#">Instagram</a>
                                    </li>
                                    <li className="spotify menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" href="/#">Spotify</a>
                                    </li>
                                    <li className="telegram menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" href="/#">Telegram</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header